import axios from 'axios';
import config from '../config.json';
import { AppToaster } from '../Components/Toaster';
import { Intent } from '@blueprintjs/core';
import moment from 'moment';

const lab_url = config.lab_service;
const report_url = config.report_service;
const user_url = config.user_service;

export const GetCategories = () => {
  const lab_id = localStorage.getItem('enterprise_token');

  let criteria = {
    params: {
      filter: {
        where: {
          lab_id: lab_id,
        },
      },
    },
  };

  return axios.get(lab_url + '/categories', criteria);
};

export const GetRequestTestService = id => {
  return axios.get(lab_url + '/request_tests/' + id);
};

export const GetTestReference = id => {
  let criteria = {
    params: {
      filter: {
        where: {
          test_id: id,
        },
      },
    },
  };

  return axios.get(lab_url + '/lab_test_references', criteria);
};

export const GeneratePdf = data => {
  return axios.post(report_url + '/generate', { data });
};

export const GeneratePdfLogo = async data => {
  return axios.post(report_url + '/generate_with_logo', { data });
};

export const GetPrices = () => {
  const lab_id = localStorage.getItem('enterprise_token');

  let criteria = {
    params: {
      filter: {
        where: {
          lab_id,
        },
      },
    },
  };

  return axios.get(lab_url + '/categories', criteria);
};

export const sendReport = async (
  patient,
  doctor,
  test_list,
  branch_id,
  lab_name,
  total,
  discount,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  const tests = test_list.map(test => {
    return { name: test.name, precio: test.price };
  });

  if (tests.length > 0) {
    try {
      const data = {
        name: patient?.names || '',
        last_name: patient?.last_name || '',
        phone_number: patient?._phones?.[0]?.number ?? null,
        years_old: moment().diff(patient?.birthdate, 'years') || null,
        sex: patient?.sex || null,
        doctor: doctor || null,
        test_list: test_list,
        total: total,
        discount: discount || null,
        branch_id: branch_id,
        navigator: navigator.userAgent,
        enterprise: lab_name,
        enterprise_id: lab_id,
      };

      await axios.post(config.lab_service + '/request_tests/logger', {
        ...data,
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const createRequestTest = async (
  patient,
  doctor_id,
  tests_selected,
  branch_id,
  discount,
  on_account,
  payment_method,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  try {
    const user_id = localStorage.getItem('user_id');

    let data = {
      lab_id: lab_id,
      patient_id: patient.id,
      doctor_id: doctor_id,
      years_old: moment().diff(patient.birthdate, 'years'),
      sex: patient.sex,
      discount: discount,
      on_account: on_account,
      tests: tests_selected,
      created_by: user_id,
      branch_id: branch_id,
      payment_method,
    };

    return axios.post(lab_url + '/request_tests', data);
  } catch (err) {
    console.log(err);
  }
};

//deprecated
export const SaveTest = async (
  phone_number,
  name,
  last_name,
  doctor,
  sex,
  years_old,
  discount,
  on_account,
  tests_selected,
  branch_id,
  payment_method,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  if (phone_number && phone_number.length > 5) {
    let criteria = {
      params: {
        filter: {
          where: {
            phonenumber: phone_number,
          },
        },
      },
    };

    try {
      let res = await axios.get(user_url + '/users', criteria);
      const user_id = localStorage.getItem('user_id');

      if (res.data.length > 0) {
        let data = {
          lab_id: lab_id,
          patient_id: res.data[0].id,
          doctor_id: doctor,
          years_old: years_old,
          sex: sex,
          discount: discount,
          on_account: on_account,
          tests: tests_selected,
          status: 'processing',
          created_by: user_id,
        };

        return axios.post(lab_url + '/request_tests', data);
      } else {
        let data_user = {
          full_name: name,
          last_names: last_name,
          phonenumber: phone_number,
          sex: sex,
          years_old: years_old,
        };

        let res_x = await axios.post(user_url + '/users', data_user);

        let data = {
          lab_id: lab_id,
          patient_id: res_x.data.id,
          doctor_id: doctor,
          years_old: years_old,
          sex: sex,
          discount: discount,
          on_account: on_account,
          tests: tests_selected,
          status: 'processing',
          created_by: user_id,
        };

        return axios.post(lab_url + '/request_tests', data);
      }
    } catch (err) {
      console.log(err);
      AppToaster.show({
        message: 'Error al crear solicitud de prueba',
        timeout: 3000,
        intent: Intent.DANGER,
      });
    }
  } else {
    let data_user = {
      full_name: name,
      last_names: last_name,
      phonenumber: phone_number,
      sex: sex,
      years_old: years_old,
    };

    try {
      let res_y = await axios.post(user_url + '/users', data_user);
      const user_id = localStorage.getItem('user_id');

      let data = {
        lab_id: lab_id,
        patient_id: res_y.data.id,
        doctor_id: doctor,
        years_old: years_old,
        sex: sex,
        discount: discount,
        on_account: on_account,
        tests: tests_selected,
        status: 'processing',
        created_by: user_id,
        branch_id: branch_id,
        payment_method,
      };

      return axios.post(lab_url + '/request_tests', data);
    } catch (err) {
      console.log(err);
      AppToaster.show({
        message: 'Error al crear solicitud de prueba',
        timeout: 3000,
        intent: Intent.DANGER,
      });
    }
  }
};

export const LoadProcessingTestsService = () => {
  const lab_id = localStorage.getItem('enterprise_token');

  axios.defaults.headers.common['X-Access-Token'] = localStorage.getItem('token');

  const criteria = {
    params: {
      filter: {
        where: {
          status: 'processing',
          lab_id: lab_id,
        },
        order: 'created_at DESC',
      },
    },
  };

  return axios.get(lab_url + '/request_tests', criteria);
};

export const LoadTestsResultsService = page => {
  const lab_id = localStorage.getItem('enterprise_token');

  const criteria = {
    params: {
      filter: {
        where: {
          lab_id: lab_id,
        },
        order: 'created_at DESC',
      },
      page,
    },
  };

  return axios.get(lab_url + '/test_results', criteria);
};

export const CompleteStatusRequestTestLegacy = async (
  id,
  test_value_references,
  general_obs,
  internal_code,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  let criteria = {
    params: {
      filter: {
        where: {
          request_id: id,
        },
      },
    },
  };

  try {
    let res_delete = null;

    let res = await axios.get(lab_url + '/test_results', criteria);

    if (res.data.data[0]) {
      res_delete = await axios.delete(
        lab_url + '/test_results/' + res.data.data[0].id,
      );
    }

    let test_data = {
      lab_id: lab_id,
      request_id: id,
      created_by: 'sigal',
      test_list_values: test_value_references,
      general_obs: general_obs,
      internal_code,
    };

    const res_test_result = await axios.post(lab_url + '/test_results', test_data);

    const data = {
      status: 'complete',
    };

    let res_complete = await axios.patch(lab_url + '/request_tests/' + id, data);

    let res_aux = await axios.get(lab_url + '/test_results', criteria);

    return res_aux;
  } catch (e) {
    console.log(e.message);
  }
};

export const SaveTestValues = async (
  id,
  test_value_references,
  general_obs,
  internal_code,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  let criteria = {
    params: {
      filter: {
        where: {
          request_id: id,
        },
      },
    },
  };

  try {
    let res_delete = null;

    let res = await axios.get(lab_url + '/test_results', criteria);

    if (res.data.data[0]) {
      res_delete = await axios.delete(
        lab_url + '/test_results/' + res.data.data[0].id,
      );
    }

    let test_data = {
      lab_id: lab_id,
      request_id: id,
      created_by: 'sigal',
      test_list_values: test_value_references,
      general_obs: general_obs,
      internal_code,
    };

    const res_test_result = await axios.post(lab_url + '/test_results', test_data);

    let res_aux = await axios.get(lab_url + '/test_results', criteria);

    return res_aux;
    //return res_test_result;
  } catch (e) {
    console.log(e.message);
  }
};

export const CompleteStatusRequestTest = async id => {
  const data = {
    status: 'complete',
  };

  let res = await axios.patch(lab_url + '/request_tests/' + id, data);
  return res;
};

export const DoneRequestTest = async (
  id,
  test_value_references,
  general_obs,
  internal_code,
) => {
  try {
    await SaveTestValues(id, test_value_references, general_obs, internal_code);
    await CompleteStatusRequestTest(id);
  } catch (error) {
    return error;
  }
};

export const SaveTestResult = async (
  request_id,
  test_value_references,
  general_obs,
  internal_code,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  let criteria = {
    params: {
      filter: {
        where: {
          request_id: request_id,
        },
      },
    },
  };

  let res_complete = null;

  try {
    let res = await axios.get(lab_url + '/test_results', criteria);

    if (res.data.data[0]) {
      let res_delete = await axios.delete(
        lab_url + '/test_results/' + res.data.data[0].id,
      );
    }

    const user_id = localStorage.getItem('user_id');

    let test_data = {
      lab_id: lab_id,
      request_id: request_id,
      created_by: user_id,
      test_list_values: test_value_references,
      general_obs: general_obs,
      internal_code: internal_code,
    };

    res_complete = await axios.post(lab_url + '/test_results', test_data);
  } catch (e) {
    console.log(e.message);
  }

  return res_complete;
};

export const ProcessingStatusRequestTest = id => {
  const data = {
    status: 'processing',
  };

  return axios.patch(lab_url + '/request_tests/' + id, data);
};

export const GetTestsValues = request_id => {
  let criteria = {
    params: {
      filter: {
        where: {
          request_id: request_id,
        },
      },
    },
  };

  return axios.get(lab_url + '/test_results', criteria);
};

export const UpdateRequest = (id, key, value) => {
  const data = {
    [key]: value,
  };

  return axios.patch(lab_url + '/request_tests/' + id, data);
};

export const downloadExcel = async (branch_id, month, year, lab_name) => {
  const lab_id = localStorage.getItem('enterprise_token');
  const params = {
    params: {
      month,
      year,
      branch_id,
      lab_name: lab_name,
      lab_id: lab_id,
    },
    responseType: 'blob',
  };

  try {
    const response = await axios.get(lab_url + '/doctor-finance/v1/report', params);

    const contentDisposition = response.headers['content-disposition'];
    let fileName =
      lab_name.replace(/\s+/g, '') +
      '_sucursal_' +
      branch_id +
      '_mes_' +
      month +
      '_' +
      year +
      '_ReporteGeneral.xlsx'; // Default filename
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    link.click();
  } catch (error) {
    console.error(error);
  }
};

export const downloadFeeExcel = async (branch_id, month, year, lab_name) => {
  const lab_id = localStorage.getItem('enterprise_token');
  const params = {
    params: {
      month,
      year,
      branch_id,
      lab_name: lab_name,
      lab_id: lab_id,
    },
    responseType: 'blob',
  };

  try {
    const response = await axios.get(
      lab_url + '/doctor-finance/v1/reportFee',
      params,
    );

    const contentDisposition = response.headers['content-disposition'];
    let fileName =
      lab_name.replace(/\s+/g, '') +
      '_sucursal_' +
      branch_id +
      '_mes_' +
      month +
      '_' +
      year +
      '_Comiciones.xlsx'; // Default filename

    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    link.click();
  } catch (error) {
    console.error(error);
  }
};
